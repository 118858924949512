.Wrapper {
  width: 100%;
  overflow-x: auto;

  .Table {

    th {
      &.EmailHeader {
        width: 50%;
      }

      &.BalanceHeader {
        width: 10%;
      }

      &.customerTypeHeader {
        width: 25%;
      }

      &.ViewHeader {
        width: 10%;
      }
    }

    td {
      vertical-align: middle;
    }

    .LoadingWrapper {
      padding: 2rem 0;
      display: flex;
      justify-content: center;
    }
  }
}