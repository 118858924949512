.Wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .Heading {
    margin-top: -2rem;
    margin-bottom: 2rem;
  }

  .Form {
    width: 100%;
    padding: 1.5rem;
    max-width: 400px;
    border-radius: 10px;
    border: 1px solid #dedede;
  }
}